<template>
  <div class="setting_form p-4 clear">
    <div class="white_box">
      <!--Body-->
      <div class="body">
        <div class="body-title mt-1">
          <h6>General Settings</h6>
        </div>
        <div class="body-section mt-2">
          <div class="input_field">
            <div class="w-50 pr-3">
              <div :class="{'input-field-error' : getWhiteLabelValidations.agency_name.name || getWhiteLabelValidations.agency_name.nameLength}" class=" input_icon_text d-flex align-items-center profile__box">
                <div class="content">
                  <div class="url">
                    Agency Name

                    <div class="avatar ml-1">
                      <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                        <div class="tool_tip_box">
                          <div class="inner_box">
                            <p>Your agency name will be used all across the application as a branding.</p>
                          </div>
                        </div>
                      </i>
                    </div>

                  </div>
                  <div class="name">
                    <div class="validation_input">
                      <input v-model="getWhiteLabel.agency_name" placeholder="Enter your agency name" type="text">
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="getWhiteLabelValidations.agency_name.name" class="input-error">{{ whiteLabelMessage.name }}</span>
              <span v-else-if="getWhiteLabelValidations.agency_name.nameLength" class="input-error">{{ whiteLabelMessage.nameLength }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <upload-image-or-url
                v-if="!getWhiteLabelLoaders.fetch"
                :imageUrl="getWhiteLabel.header_logo"
                @uploadImage="uploadHeaderLogo($event)"
                :validations="getWhiteLabelValidations.header_logo"
                :id="'header_logo'"
                title="Upload Header Logo (.png, .jpg, .jpeg, .gif, .ico)"
                recomendedSize="(250 x 250)px"
                tooltip="This logo image will be used in the top header bar of the application."
                urlTitle="Upload Header Logo image url"
                placeholder="Enter the Header logo image url (250 x 150 recommended)"
                ref="upload_head_logo"
                :isImagePreviewEnabled="true"
              ></upload-image-or-url>
            </div>
            <div class="col">

              <upload-image-or-url
                v-if="!getWhiteLabelLoaders.fetch"
                :imageUrl="getWhiteLabel.favicon"
                @uploadImage="uploadFavicon($event)"
                title="Favicon (.png, .jpg, .jpeg, .gif, .ico)"
                :validations="getWhiteLabelValidations.favicon"
                :id="'favicon'"
                recomendedSize="(16 x 16)px"
                tooltip="This Favicon will be displayed in browser tabs, browser history, toolbar apps, bookmarks dropdown, search bar, and search bar recommendations."
                urlTitle="Favicon Image URL"
                placeholder="Enter the favicon image url (16 x 16 recommended)"
                ref="favicon"
                :isImagePreviewEnabled="true"
              ></upload-image-or-url>

            </div>
          </div>

          <div class="mt-3 input_field">
            <div class="w-50 pr-3">
              <div :class="{'input-field-error' : getWhiteLabelValidations.privacy_policy.url || getWhiteLabelValidations.privacy_policy.urlLength || getWhiteLabelValidations.privacy_policy.urlValid}" class=" input_icon_text d-flex align-items-center profile__box">
                <div class="content">
                  <div class="url">Privacy Policy URL</div>
                  <div class="name">
                    <div class="validation_input">
                      <input v-model="getWhiteLabel.privacy_policy" placeholder="Enter privacy policy url" type="text">
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="getWhiteLabelValidations.privacy_policy.url" class="input-error">{{ whiteLabelMessage.url }}</span>
              <span v-else-if="getWhiteLabelValidations.privacy_policy.urlValid" class="input-error">{{ whiteLabelMessage.urlValid }}</span>
              <span v-else-if="getWhiteLabelValidations.privacy_policy.urlLength" class="input-error">{{ whiteLabelMessage.urlLength }}</span>
            </div>
          </div>

          <div class="mt-3 input_field">
            <div class="w-50 pr-3">
              <div :class="{'input-field-error' : getWhiteLabelValidations.terms_of_service.url || getWhiteLabelValidations.terms_of_service.urlLength || getWhiteLabelValidations.terms_of_service.urlValid}" class=" input_icon_text d-flex align-items-center profile__box">
                <div class="content">
                  <div class="url">Terms of Service URL</div>
                  <div class="name">
                    <div class="validation_input">
                      <input v-model="getWhiteLabel.terms_of_service" placeholder="Enter your term of service url" type="text">
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="getWhiteLabelValidations.terms_of_service.url" class="input-error">{{ whiteLabelMessage.url }}</span>
              <span v-else-if="getWhiteLabelValidations.terms_of_service.urlValid" class="input-error">{{ whiteLabelMessage.urlValid }}</span>
              <span v-else-if="getWhiteLabelValidations.terms_of_service.urlLength" class="input-error">{{ whiteLabelMessage.urlLength }}</span>
            </div>
          </div>

          <hr>
          <div class="body-title mt-1">
            <h6>Login Page Settings</h6>
          </div>
          <div class="row">
            <div class="col">
              <upload-image-or-url
                v-if="!getWhiteLabelLoaders.fetch"
                :imageUrl="getWhiteLabel.login_logo"
                @uploadImage="uploadLoginLogo($event)"
                :validations="getWhiteLabelValidations.login_logo"
                :id="'login_logo'"
                recomendedSize="(250 x 250)px"
                title="Login Page Logo (.png, .jpg, .jpeg, .gif, .ico)"
                tooltip="Login page logo will be displayed on Login page, Join team page, forgot password page."
                urlTitle="Upload login page image url"
                placeholder="Enter the login page image url (250 x 150 recommended)"
                ref="login_logo"
                :isImagePreviewEnabled="true"
              ></upload-image-or-url>
            </div>
            <div class="col">
              <upload-image-or-url
                v-if="!getWhiteLabelLoaders.fetch"
                :imageUrl="getWhiteLabel.app_loader"
                @uploadImage="uploadAppLoader($event)"
                :validations="getWhiteLabelValidations.app_loader"
                :id="'app_loader'"
                title="App Loader Image (.png, .jpg, .jpeg, .gif, .ico)"
                urlTitle="Upload app loader mage URL"
                recomendedSize="(250 x 250)px"
                tooltip="This image will be used as a loader image all across the application."
                placeholder="Enter the app loader image url (250 x 150 recommended)"
                ref="app_loader"
                :isImagePreviewEnabled="true"
              ></upload-image-or-url>
            </div>
          </div>
          <hr>
          <button @click="fetchWhiteLabel()" type="button" class="mr-2 btn---cta light-blue btn-round  btn-bold"><span>Discard changes</span>
          </button>
          <button @click="applyWhiteLabelChanges()" class="btn---cta d-inline-flex btn-blue with-shadow btn-round   btn-bold"><!----><span>Apply changes</span>
            <clip-loader v-if="getWhiteLabelLoaders.store" class="icon ml-2" style="right: 30px;" :size="'12px'" :color="'white'"></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {whiteLabelMessage} from "@/common/attributes";

export default {
  name: 'WhitelabelGeneralSettings',
  data () {
    return {
      whiteLabelMessage: whiteLabelMessage
    }
  },
  computed: {
    ...mapGetters(['getWhiteLabel', 'getWhiteLabelValidations', 'getWhiteLabelLoaders'])
  },
  components: {
    'upload-image-or-url': () => import('@/ui/UploadImageOrUrl')
  },
  methods: {
    ...mapActions(['fetchWhiteLabel']),
    uploadHeaderLogo (event) {
      this.getWhiteLabel.header_logo = event ? event : ''
    },
    uploadFavicon (event) {
      this.getWhiteLabel.favicon = event ? event : ''
    },
    uploadLoginLogo (event) {
      this.getWhiteLabel.login_logo = event ? event : ''
    },
    uploadAppLoader (event) {
      this.getWhiteLabel.app_loader = event ? event : ''
    }
  },
  watch: {
    'getWhiteLabel.agency_name' (value) {
      this.whiteLabelAgencyNameValidation()
    },
    'getWhiteLabel.privacy_policy' (value) {
      this.whiteLabelCommonUrlValidation('privacy_policy')
    },
    'getWhiteLabel.terms_of_service' (value) {
      this.whiteLabelCommonUrlValidation('terms_of_service')
    }
  }
}
</script>
<style scoped lang="less">
.setting_form {
  border-left: 1px solid #d0d6e5;
  min-height: 132px;

  .white_box {
    .box_inner {
      .body {
        .body-title {
          h6 {
            font-weight: bold;
            font-size: small;
          }

          p {
            font-size: small;
          }
        }

        a {
          color: #1d8bfa;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
